



















































































import { Component, Vue } from 'vue-property-decorator'
import VsFormGroup from '@/components/VsFormGroup/Index.vue'
import VsFormSection from '@/components/VsFormSection/Index.vue'
import VsLoader from '@/components/VsLoader/Index.vue'
import { getLayoutSettings } from '@/api/consoleApi/user'
import { getSettings, updateSetting } from '@/api/consoleApi/settings'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import { UserModule } from '@/store/modules/user'

@Component({
    name: 'NotificationsCampaign',
    components: {
        VsFormGroup,
        VsFormSection,
        VsLoader,
    },
})
export default class extends Vue {
    private loading = false
    private pageLoading = false
    private sentEmail = {
        active: false,
        value: '',
    }

    private pauseCampaign = {
        active: false,
        value: '',
    }

    $refs: any

    get user () {
        return UserModule.user
    }

    beforeMount () {
        this.getSettings()
    }

    private async getSettings () {
        try {
            this.pageLoading = true
            const resp = await getSettings()
            this.sentEmail = {
                active: !!resp.data.data.notifySentEmail,
                value: resp.data.data.notifySentEmail,
            }
            this.pauseCampaign = {
                active: !!resp.data.data.notifyPauseCampaign,
                value: resp.data.data.notifyPauseCampaign,
            }
        } catch (e) {
            console.log(e)
        }
        this.pageLoading = false
    }

    private async saveCustomization () {
        const valid = await this.$refs.notificationForm.validate()
        if (!valid) return
        this.cleanData()
        try {
            this.loading = true
            await updateSetting({
                notifySentEmail: this.sentEmail.value,
                notifyPauseCampaign: this.pauseCampaign.value,
            })

            const res = await getLayoutSettings()
            UserModule.SET_SETTINGS(res.data.data)

            this.$root.$vsToast({
                heading: this.$t('settings.general.personalizationSuccess'),
                aspect: VsToastAspectEnum.success,
                timeout: 3000,
            })
        } catch (e) {
            this.$root.$vsToast({
                heading: this.$t('settings.general.personalizationError'),
                aspect: VsToastAspectEnum.alert,
                timeout: 3000,
            })
            console.log(e)
        }
        this.loading = false
    }

    private cleanData () {
        if (!this.sentEmail.active) this.sentEmail.value = ''
        if (!this.pauseCampaign.active) this.pauseCampaign.value = ''
    }
}
